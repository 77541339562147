@import "./functions";

.breadcrumb-last {
	border-radius: 3px;
	padding: 0px 5px;
	background: var(--breadcrumb-bg-last);
}

.ant-breadcrumb li:last-child {
	color: var(--breadcrumb-text-color-last);
}