.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	background: transparent;
	color: var(--default-btn-color);
	border-color: var(--default-btn-color);

	&::before {
		background-color: var(--default-btn-color);
	}

	&:hover {
		color: var(--default-btn-color);
		border-color: var(--default-btn-color);

		&::before {
			background-color: var(--default-btn-color);
		}
	}

	&:first-child {
		border-color: var(--default-btn-color);
	}
}

.ant-radio-button-wrapper {
	border: var(--input-border-color);
	
	&:first-child {
		border-start-start-radius: 2px;
		border-end-start-radius: 2px;
		border: var(--input-border-color);
	}

	&:last-child {
		border-start-end-radius: 2px;
		border-end-end-radius: 2px;
	}

	&:hover {
		color: var(--default-btn-color);
	}
}

.ant-radio-wrapper {
	color: var(--theme-main-text-color);

	.ant-radio-inner {
		border: var(--input-border-color) ;
		background-color: #ffffff;
	}

	.ant-radio-checked {
		.ant-radio-inner {
			border-color: var(--default-btn-color);
			background-color: var(--default-btn-color);
		}

		&::after {
			border: 1px solid var(--default-btn-color);
		}
	}
}
