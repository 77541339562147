.ant-drawer {
	.ant-drawer-title {
		color: var(--primary-text);
	}

	.ant-drawer-close {
		color: var(--primary-text);

		&:hover {
			color: var(--primary-text);
			background-color: transparent;
		}

		&:active {
			background-color: transparent;
		}
	}

	.ant-drawer-header {
		display: flex;
		padding: 15px 30px;
		align-items: center;
		gap: 10px;
		align-self: stretch;
		background: var(--primary-color); 
		box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12),
			0px 2px 4px -1px rgba(20, 62, 98, 0.15);
	}

	.ant-drawer-content {
		background: var(--container-bg-color);
	}

	.ant-drawer-body {
		display: flex;
		padding: 20px 30px;
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;
		flex: 1 0 0;
		box-shadow: 7px 7px 11px 0px rgba(0, 0, 0, 0.25);
	}
}
