.deliverables-table {
	background-color: transparent;
	padding: 0;
	color: var(--theme-main-text-color);

	.ant-btn {
		color: var(--primary-text);
	}

	.ant-empty-normal {
		.ant-empty-description {
			color: var(--theme-main-text-color);
		}
		.ant-empty-image {
			mix-blend-mode: exclusion;
		}
	}

	.ant-table.ant-table-bordered {
		> .ant-table-container {
			border-inline-start: none;

			> .ant-table-content {
				background: rgba(255, 255, 255, 0.1);
				> table {
					> thead {
						> tr {
							> th {
								border-inline-end: 1px solid rgba(255, 255, 255, 0.25);

								&:first-child {
									border-inline-start: 0px;
								}

								&:last-child {
									border-inline-end: 0px;
								}
							}

							> td {
								border-inline-end: 1px solid rgba(255, 255, 255, 0.25);
							}
						}
					}

					> tbody {
						> tr {
							> td {
								&:last-child {
									border-inline-end: 0px;
								}
							}
						}
						> tr.ant-table-placeholder {
							> td {
								border-bottom: none;
							}
						}
					}
				}
			}
		}
	}

	.ant-table-thead {
		> tr {
			> th,
			td {
				background: var(--primary-color) !important;
				color: var(--primary-text) !important;
				font-weight: 500;
				padding: 11px 16px;
				border-bottom: none;
			}
		}
	}

	.ant-table-column-sorter {
		color: var(--primary-text);
	}

	.ant-table-tbody {
		.ant-table-row {
			> .ant-table-cell-row-hover {
				background-color: #4f6667; // play with ligthen darken or use a general color that suits dark/light mode
			}
		}
		> tr {
			> td {
				border-bottom: 1px solid rgba(255, 255, 255, 0.25);
			}
		}
		> tr.ant-table-placeholder {
			background: transparent;

			&:hover {
				> td {
					background: transparent;
				}
			}
		}
	}

	.ant-spin-nested-loading,
	.ant-spin-container,
	.ant-table-container,
	.ant-table-content {
		height: 100%;
		text-align: left;
	}

	.ant-spin-nested-loading {
		> div {
			.ant-spin {
				max-height: inherit;
			}
		}
	}

	.ant-pagination {
		.ant-pagination-item-active {
			background-color: transparent;
			border-radius: 2px;
			border: 1px solid var(--default-btn-color);

			a {
				color: var(--default-btn-color);
			}
		}
	}
}

.task-overview {
	&.generic-container {
		gap: 20px;
		overflow: auto;
	}

	.top-container.main-container {
		width: "100%";
		display: flex;
		flex-direction: column;

		.details-content {
			padding: 30px;
			display: flex;
			flex-direction: column;
			flex: 1;
			height: 100%;
			position: relative;
			gap: 20px;

			.task-details-content {
				margin-top: auto;
				display: flex;
				align-items: center;
				flex-direction: row;
				gap: 10px;
			}
		}
	}

	.bottom-container.main-container {
		min-height: 85%;
		flex: 1;
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;

		.generic-content {
			flex: 1;
			display: flex;
			flex-wrap: inherit;
			flex-direction: column;
			align-items: flex-start;
			padding: 0;
			gap: 0;
			overflow-x: auto;
			max-height: 100%;

			.kanban-columns-container {
				display: flex;
				flex: 1;
				align-items: flex-start;
				padding: 30px;
				gap: 25px;
				align-self: stretch;
				overflow: auto;
			}
		}
	}
}

.deliverable-side-container {
	position: relative;
	display: flex;
	flex: 1;
	flex-direction: column;
	background: var(--deliverable-side-container-bg);
	border-radius: 3px;

	.deliverable-card-close-button {
		position: absolute;
		right: 0;
		color: var(--theme-main-text-color);
	}

	.deliverable-card-form {
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin-top: 10px;
		margin-bottom: 10px;
		padding: 20px;
		row-gap: 20px;
		width: 100%;

		&.padding-resolved {
			padding: 20px;
		}

		&.padding-not-resolved {
			padding: 20px 20px 40px;
		}

		.disabled-reason {
			color: rgba(123, 123, 123, 0.8);
			font-size: 14px;
			font-style: italic;
			font-weight: 400;
			line-height: 22px;
		}

		.deliverable-card-form-item {
			display: grid;
			gap: 10px;
			grid-template-rows: 20px auto;
		}

		.deliverable-card-form-label {
			height: min-content;
			text-align: left;
			font-size: 14px;
			opacity: 0.45;
		}

		.form-item-content {
			font-size: 14px;
			text-align: left;
			height: min-content;
			color: var(--theme-main-text-color);
		}
	}
}

.deliverable-tag {
	white-space: normal;
	word-wrap: break-word;
	border-radius: 2px;
	border: 1px solid var(--theme-main-text-color);
	background: rgba(113, 113, 113, 0.5);
	color: var(--theme-main-text-color);
	margin-right: 5px;
}

.attachment-date {
	color: var(--theme-main-text-color);
}

.attachment-close {
	color: var(--theme-main-text-color);
}

.comment-container {
	background-color: var(--comment-box-bg-color);
}

.comment-date-time {
	color: var(--comment-box-date-time);
}
