.login-body {
	height: 100%;
}

.container {
	overflow: hidden;
}

.left-container {
	width: 101%;
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url("../image/loginBackground.png");
	background-position: left center; /* Align the image's right edge with the container's right edge */
}

.right-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	right: 0;
	top: 0;
	bottom: 0;
	position: fixed;
	height: 100%;
	min-width: 450px;
	width: 30%;
	background-image: url("../image/signInBackground.png");
	background-size: cover;
	// .login-form {
	// 	.ant-divider {
	// 		font-size: 14px;
	// 	}
	// 	.ant-divider-with-text::before,
	// 	.ant-divider-with-text::after,
	// 	.ant-divider-horizontal.ant-divider-with-text::before,
	// 	.ant-divider-horizontal.ant-divider-with-text::after {
	// 		border-block-start-color: inherit;
	// 	}
	// }
}

.login-container {
	min-width: 275px;
	color: white;
	text-align: center;
}

.login-container-footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	bottom: 50px;
	color: rgba(255, 255, 255, 0.85);
}

.login-form-button {
	width: 100%;
}

.login-icon {
	color: #ffffff73;
}

.login-input {
	background: none;
	border: 1px solid #7d7d7d;
}

.login-input .ant-input {
	background: none;
}

.normal-login-button {
	background: #299ba3;
	border: 1px solid #299ba3;

	&.ant-btn-primary {
		background: #299ba3;
		border: 1px solid #299ba3;
	}
}

.microsoft-login-button {
	background: #141414 !important;
	color: #ffffffd9 !important;
	border: 1px solid #434343;

	&.ant-btn-secondary {
		background: #141414;
		border: 1px solid #434343;

		&:hover {
			color: #7cb9bd !important;
			border: 1px solid #7cb9bd !important;
			background: #141414 !important;
		}
	}
}
