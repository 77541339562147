.lookup-missing-tooltip {
	position: absolute;
	padding: 0px 11px;
	color: #faad14;
	top: 5px;
	display: flex;
	gap: 8px;
}

.boxlist-input {
	border: var(--input-border-color);
}

.rolespermissions-tab {
	border: var(--input-border-color);
	padding: "10px";
}