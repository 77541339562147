.well-details-top-content {
	width: 100%;
	display: flex;
	flex-direction: column;

	.project-details-container {
		display: flex;
		flex-wrap: inherit;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		padding: 30px;
		gap: 0;
		overflow: auto;
		max-height: 100%;
	}
}

.well-details-bottom-content {
	.quick-access-content {
		max-height: 500px; /* Adjust based on expected content height */
		opacity: 1;
		transition: max-height 0.3s ease, opacity 0.3s ease;
		overflow: hidden;

		&.collapsed {
			max-height: 0;
			opacity: 0;
			pointer-events: none;
		}

		&.quick-access-margin.expanded {
			margin-bottom: 40px;
		}
	}

	.quick-access-item {
		background-color: var(--primary-color);
		color: var(--primary-text);
	}

	.document-container-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 10px;

		.document-breadcrumb {
			display: flex;
			flex-direction: row;
			gap: 8px;
			font-size: 16px;

			.document-breadcrumb-clickable-text {
				// color: rgba(255, 255, 255, 0.4);

				&:hover {
					color: var(--theme-main-hover-text-color);
				}
			}

			.document-breadcrumb-seperator {
				// color: rgba(255, 255, 255, 0.4);
			}
		}
	}

	.ant-table-wrapper {
		.ant-table.ant-table-bordered {
			> .ant-table-container {
				border-inline-start: none;
				border-top: none;

				> .ant-table-content {
					> table {
						> thead {
							> tr {
								> th {
									border-inline-end: 1px solid rgba(255, 255, 255, 0.25);

									&:first-child {
										// border-inline-end: none;
									}

									&:last-child {
										border-inline-end: 0px;
									}
								}

								> td {
									border-inline-end: 1px solid rgba(255, 255, 255, 0.25);
								}
							}
						}

						> tbody {
							background: rgba(255, 255, 255, 0.1);
							.table-clickable-icon-text {
								color: var(--theme-main-text-color);
							}
							> tr {
								> td {
									border-inline-end: 1px solid rgba(255, 255, 255, 0.25);
									&:first-child {
										// border-inline-end: none;
										// background: var(--theme-main-bg-color);
									}
									// &:nth-child(2) {
									// 	background: #003235;
									// }
								}
							}
							> tr.ant-table-placeholder {
								> td {
									border-bottom: none;
								}
							}
						}
					}
				}
			}
		}
	}
}
