.ant-popconfirm {
	.ant-popconfirm-message {
		align-items: center;

		.ant-popconfirm-title {
			color: var(--theme-main-text-color);
		}

		.ant-popconfirm-description {
			color: var(--theme-main-text-color);
		}
	}
}

.ant-popover {
	.ant-popover-arrow {
		&::before {
			background: var(--theme-main-bg-color);
		}
	}

	.ant-popover-content {
		--antd-arrow-background-color: var(--theme-main-bg-color);
		.ant-popover-inner {
			background-color: var(--theme-main-bg-color);
		}
	}
}
