.project-card {
	width: 100%;
	height: min-content;
	cursor: pointer;
	padding: 20px 30px;
	gap: 10px;
	border-radius: 5px;
	background: var(--project-cards-bg);
	border: var(--primary-color) 1px solid;
	box-shadow: 7px 7px 11px 0px rgba(0, 0, 0, 0.25);
}

.project-card-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	.ant-divider {
		border-block-start-color: var(--project-cards-text-color);
	}

	.ant-typography {
		color: var(--project-cards-text-color);
	}

	.ant-collapse {
		.ant-collapse-item {
			.ant-collapse-header {
				.ant-collapse-header-text {
					color: var(--project-cards-text-color);
				}
				.ant-collapse-expand-icon {
					color: var(--project-cards-text-color);
				}
			}
		}

		.ant-collapse-content {
			background: var(--project-cards-bg);
			color: var(--project-cards-text-color);
		}
	}
}

.project-card.task-card-disabled {
	border: 1px solid #434343;
	box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
	background: #434343;
	filter: grayscale(1);
}

.project-title-clickable {
	color: var(--project-cards-text-color);
	
	.project-name:hover {
		color: var(--default-btn-color);
	}
}

.project-title-clickable:hover {
	color: var(--default-btn-color);
}
