.timewriting-table {
	width: 100%;
	background-color: var(--timewriting-table-container-bg);
	color: var(--input-text-color);
	overflow-x: hidden;

	.header-first-row {
		padding: 15px 12px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: var(--timewriting-table-header-bg);
		color: var(--input-text-color);
		border-left: var(--table-border);
		border-right: var(--table-border);
		border-top: var(--table-border);
	}

	.table-contents {
		width: 100%;
		height: auto;
		overflow: auto;
		display: grid;

		&.weekly-summary {
			display: grid;
			/*
				Use `grid-template-rows` ONLY, to set all rows to a specific height.
				Use BOTH `grid-template-rows` and `grid-auto-rows` to set ONLY the first row and let the extra rows have the same height.
			*/
			grid-template-rows: 58px;
			grid-auto-rows: auto;

			.header-second-row {
				display: grid;
				grid-template-columns: 300px repeat(7, minmax(120px, 1fr));
				background-color: var(--timewriting-table-header-bg);
				color: var(--input-text-color);
				border-left: var(--table-border);
				border-right: var(--table-border);
				border-bottom: var(--table-border);

				.header-day-duration {
					color: var(--default-btn-color);
				}
			}

			.weekly-summary-row {
				display: grid;
				grid-template-columns: 300px repeat(7, minmax(120px, 1fr));
				border-left: var(--table-border);
				border-right: var(--table-border);
				border-bottom: var(--table-border);

				.weekly-summary-first-child {
					background-color: var(--timewriting-table-header-bg);
					color: var(--input-text-color);
				}
			}
		}

		&.timesheet-management {
			display: grid;
			grid-template-rows: 58px;
			grid-auto-rows: auto;

			.header-second-row {
				display: grid;
				grid-template-columns: 300px repeat(8, minmax(120px, 1fr));
				background-color: var(--timewriting-table-header-bg);
				color: var(--input-text-color);
				border-left: var(--table-border);
				border-right: var(--table-border);
				border-bottom: var(--table-border);

				.header-day-content {
					width: 100%;
					padding: 12px;
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			}

			.timesheet-management-row {
				display: grid;
				grid-template-columns: 300px repeat(8, minmax(120px, 1fr));
				border-left: var(--table-border);
				border-right: var(--table-border);
				border-bottom: var(--table-border);

				.timesheet-management-first-child {
					background-color: var(--timewriting-table-header-bg);
					color: var(--input-text-color);
				}
			}

			.no-members {
				padding: 32px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: var(--timewriting-table-container-bg);
				color: var(--input-text-color);
				border-left: var(--table-border);
				border-right: var(--table-border);
				border-bottom: var(--table-border);
			}
		}

		.cell {
			display: flex;
			justify-content: center;
			flex-direction: column;
			gap: 4px;
			align-items: center;
			background-color: var(--timewriting-table-cell-bg);
			color: var(--input-text-color);
			transition: background-color 0.3s ease;
			cursor: pointer;
			pointer-events: auto;

			&:hover {
				background-color: var(--timewriting-table-cell-bg-hover) !important;
			}

			&.cell-disabled {
				background-color: var(--timewriting-table-cell-disabled);
				cursor: not-allowed;
				&:hover {
					background-color: var(--timewriting-table-cell-disabled) !important;
				}
			}

			&.cell-submitted {
				background-color: var(--timewriting-table-cell-disabled);
				cursor: default;
				pointer-events: none;
				&:hover {
					background-color: var(--timewriting-table-cell-disabled) !important;
				}

				&.cell-approved button,
				button {
					background-color: var(--timewriting-table-button-disabled-bg);
					border: 1px solid rgba(217, 217, 217, 0.3);
					cursor: pointer;
					pointer-events: auto;
				}

				&.cell-rejected button {
					background-color: var(--timewriting-table-button-disabled-bg);
					border: 1px solid rgba(217, 217, 217, 0.3);
					cursor: pointer;
					pointer-events: auto;
				}
			}
		}

		.cell:not(:last-child) {
			border-right: var(--table-border);
		}
	}
}

.review-popconfirm.ant-popconfirm .ant-popconfirm-message {
	align-items: start;
	margin: 10px 0px;
}

.review-history-popover {
	&.ant-popover {
		.ant-popover-arrow::before {
			background-color: var(--timewriting-table-container-bg);
		}
		.ant-popover-content .ant-popover-inner {
			background-color: var(--timewriting-table-container-bg);
			border-radius: 0;
			width: 350px;

			.review-history-popover-content {
				display: flex;
				flex-direction: column;
				gap: 10px;
				.review-history-popover-content-item {
					justify-content: space-between;
					display: flex;
					gap: 10px;
					align-items: center;
				}
			}
		}
	}
}

// After revamp

.week-navigator {
	padding: 15px 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--timewriting-table-header-bg);
	color: var(--input-text-color);
	border-left: var(--table-border);
	border-right: var(--table-border);
	border-top: var(--table-border);
}

.timewriting-grid-table {
	display: grid;

	.timewriting-row-header {
		border-top: 0 !important;
	}

	.timewriting-grid-header {
		background-color: var(--timewriting-table-header-bg);
		color: var(--input-text-color);
	}

	.timewriting-grid-cell {
		border: var(--table-border);
		padding: 12px;
		display: flex;
		align-items: center;
	}

	.cell {
		display: flex;
		justify-content: center;
		flex-direction: column;
		gap: 4px;
		align-items: center;
		background-color: var(--timewriting-table-cell-bg);
		color: var(--input-text-color);
		transition: background-color 0.3s ease;
		cursor: pointer;
		pointer-events: auto;

		&:hover {
			background-color: var(--timewriting-table-cell-bg-hover) !important;
		}

		&.cell-disabled {
			background-color: var(--timewriting-table-cell-disabled);
			cursor: not-allowed;
			&:hover {
				background-color: var(--timewriting-table-cell-disabled) !important;
			}
		}

		&.cell-submitted {
			background-color: var(--timewriting-table-cell-disabled);
			cursor: default;
			pointer-events: none;
			&:hover {
				background-color: var(--timewriting-table-cell-disabled) !important;
			}

			&.cell-approved button,
			button {
				background-color: var(--timewriting-table-button-disabled-bg);
				border: 1px solid rgba(217, 217, 217, 0.3);
				cursor: pointer;
				pointer-events: auto;
			}

			&.cell-rejected button {
				background-color: var(--timewriting-table-button-disabled-bg);
				border: 1px solid rgba(217, 217, 217, 0.3);
				cursor: pointer;
				pointer-events: auto;
			}
		}
	}

	.color-default-btn {
		color: var(--default-btn-color);
	}
}
