.ant-checkbox-wrapper {
	color: var(--theme-main-text-color);
	.ant-checkbox {
		.ant-checkbox-inner {
			background-color: var(--theme-main-bg-color);

			&:after {
				border-color: var(--theme-main-text-color);
			}
		}
	}
}
