.leave-changes-modal {
	.ant-modal {
		.ant-modal-content {
			margin-right: auto;
			max-width: 318px;
			border-radius: 2px;

			.ant-modal-footer {
				background: transparent;
				justify-content: space-between;
			}
		}
	}
}
