.pie-slice {
	stroke: rgba(0, 0, 0, 0.5) !important;
	stroke-width: 1px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}
.late {
	fill: rgba(255, 0, 0, 0.5) !important;
}
.early {
	fill: rgba(0, 255, 0, 0.5) !important;
}
.on-time {
	fill: rgba(0, 0, 255, 0.5) !important;
}
