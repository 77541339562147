.editor-container {
    width: 100%;
    border-radius: 2px;
    border: var(--input-border-color);
}

.ql-container.ql-snow {
    height: auto;
    border: none !important;
}

.ql-editor {
    padding: 5px 12px;
}

.editor-container .quill {
    display: flex;
    flex-direction: column-reverse; // Reverses the order of child elements
}

.editor-container .ql-toolbar.ql-snow {
    padding: 0px !important;
    border-top: var(--input-border-color);
    border-left: none;
    border-right: none;
    border-bottom: none;
}

.ql-editor.ql-blank::before {
    content: attr(data-placeholder) !important; /* Ensure the placeholder text is displayed */
    color: var(--input-border-color) !important;
    outline: none !important; /* Remove outline */
    resize: none !important; /* Disable resizing */
    font-style: normal !important; /* Normal font weight */
}

.ql-formats {
    // Add your styling for the editor box if needed
    margin-right: 0 !important;
    padding: 6px 15px !important;
}

.ql-formats:not(:last-child) {
    border-right: var(--input-border-color);
}

.ql-toolbar.ql-snow .ql-picker-label {
    border: none !important;
}

/* Toolbar Heading Label */
.ql-header.ql-picker .ql-picker-label {
    color: var(--theme-main-text-color);
    padding: 0px 12.5px !important;
}

/* Lists */
.comment-box ol, .comment-box ul  {
    padding-left: 39px;
}

/* Toolbar SVGs */
.ql-snow .ql-stroke {
    stroke: var(--theme-main-text-color) !important;
}

/* Toolbar SVGs Button */
.ql-snow.ql-toolbar button {
    stroke: var(--theme-main-text-color);
}