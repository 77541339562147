.project-settings-tab-content {
	flex: 1;
	display: flex;
	flex-direction: row;
	overflow: auto;
	gap: 20px;
	max-height: 100%;

	.project-details-tab-content {
		flex: 1;
		display: flex;
		flex-direction: row;
		overflow: auto;

		> .main-container {
			width: 70%;
			display: flex;
			flex-direction: column;
			border-radius: 3px;

			> .generic-header {
				height: 50px;
			}

			> .generic-content {
				flex: 1;

				.project-settings-form {
					flex: 1;
					position: relative;

					.project-id {
						position: absolute;
						right: 30px;
						color: inherit;
						// color: var(--theme-main-text-color);
					}
				}
			}

			> .generic-footer {
				height: 50px;
			}
		}
	}
}
