.kanban-board-container {
	display: flex;
	flex-direction: column;
	padding: 0px !important;
	width: 100%;
	overflow: hidden;
	gap: 10px;

	.kanban-detail-container {
		display: flex;
		flex: 1;
		padding: 0 95px;
		justify-content: center;
		align-items: flex-start;
		gap: 10px;
		overflow: auto;
	}

	.kanban-columns-container {
		display: flex;
		flex: 1;
		align-items: flex-start;
		gap: 30px;
		align-self: stretch;
		overflow: auto !important;
		padding: 30px;
	}

	.kanban-columns-container > *:first-child {
		margin-left: auto;
	}

	.kanban-columns-container > *:last-child {
		margin-right: auto;
	}
}

.add-new-project-button {
	width: 100%;
	// Elemental Theme v1.0
	//	background-color: var(--primary-color) !important;
	// Elemental Theme v2.0
	background-color: var(--add-new-project-button);
}
