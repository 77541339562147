.ant-collapse {
	.ant-collapse-item {
		.ant-collapse-header {
			.ant-collapse-header-text {
				color: var(--theme-main-text-color);
			}
			.ant-collapse-expand-icon {
				color: var(--theme-main-text-color);
			}
		}
	}
}
