.ant-input {
	color: var(--theme-main-text-color);
}

.ant-input-group .ant-input-group-addon {
	border-radius: 2px;
	background-color: transparent;
}

.ant-input-group .ant-input-group-addon,
.ant-input-group .ant-input-group-addon {
	color: var(--theme-main-text-color);
	border: var(--input-border-color);
}

.ant-input {
	border-radius: 2px;
	background-color: transparent;
	width: 100%;
	border: var(--input-border-color);
}

/* Disabled Text Input */
.ant-input-disabled,
.ant-input-outlined.ant-input-disabled,
.ant-input-disabled.ant-input[disabled],
.ant-input-disabled-new {
	// background-color: var(--input-bg-disabled) !important;
	// color: var(--input-color-disabled) !important;
	border: var(--input-border-color);
}

/* Disabled Text Input default color */
// .ant-input.ant-input-disabled {
// 	background-color: var(--input-bg-disabled) !important;
// 	color: var(--input-color-disabled) !important;
// }

/* Disabled Text Input on hover */
// .ant-input.ant-input-disabled:hover {
// 	background-color: transparent;
// 	border-color: #424242;
// }

.ant-input-group-wrapper {
	width: auto;
}

.ant-input-group
	.ant-input-group-addon
	.ant-select.ant-select-single:not(.ant-select-customize-input):not(
		.ant-pagination-size-changer
	)
	.ant-select-selector {
	border: var(--input-border-color);
}

.ant-input-search {
	.ant-input-group {
		.ant-input-group-addon:last-child {
			.ant-input-search-button {
				padding: 4px 0;
				height: inherit;
				border-start-end-radius: 2px;
				border-end-end-radius: 2px;
			}
		}
	}
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:hover,
.ant-input:hover,
.ant-input:focus,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:focus-within,
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
	border-color: var(--default-btn-color);
}

.ant-input-affix-wrapper.input-clearable {
	border-radius: 2px;
	border-color: var(--input-border-color);
	color: var(--search-text-dark-color);

	.ant-input {
		background-color: transparent !important;

		&::placeholder {
			color: var(--search-text-dark-color);
		}
	}

	.ant-input-show-count-suffix {
		color: var(--theme-main-text-color);
	}

	.ant-input-clear-icon {
		color: rgba(123, 123, 123, 0.5);

		&:hover {
			color: var(--theme-main-text-color);
		}
	}

	&:hover {
		background-color: rgba(64, 169, 255, 0.1) !important;
	}
}

// Search Inputs (Dark Background)
.ant-input-affix-wrapper.input-clearable-panel {
	color: var(--search-text-light-color);

	.ant-input {
		background-color: transparent !important;

		&::placeholder {
			color: var(--search-text-light-color);
		}
	}
}

.search-input-button {
	.search-input-value {
		color: var(--search-text-dark-color);
	}
}

.ant-input-affix-wrapper {
	border-radius: 2px;
	color: var(--input-text-color);
	border: var(--input-border-color);

	.ant-input {
		background-color: transparent !important;
		color: var(--input-text-color);
		border: var(--input-border-color);

		&::placeholder {
			color: var(--input-text-color);
		}
	}

	.ant-input-clear-icon {
		color: rgba(123, 123, 123, 0.5);

		&:hover {
			color: var(--theme-main-text-color);
		}
	}

	&:hover {
		background-color: rgba(64, 169, 255, 0.1) !important;
	}
}

.ant-input-outlined:hover {
	border-color: var(--default-btn-color);
}

.ant-input-outlined {
	&:focus,
	&:focus-within {
		border-color: var(--default-btn-color);
	}
}
