.tenant-settings-tab {
	.tenant-logo {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 10px;
		align-items: center;
		margin-top: 16px;
	}
	.main-container {
		.generic-content {
			overflow: auto;
		}
	}

	.ant-radio-group {
		width: 100%;
	}

	.theme-pallete-container {
		border: var(--input-border-color);
		border-radius: 2px;
		padding: 15px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 10px;
		align-self: stretch;
		color: var(--theme-main-text-color);
	}
}
