.ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible),
.ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible),
.ant-tabs .ant-tabs-tab-btn:active,
.ant-tabs .ant-tabs-tab-remove:active,
.ant-tabs .ant-tabs-tab:hover:not(:disabled),
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: var(--default-btn-color);
}

.ant-tabs .ant-tabs-ink-bar,
.ant-tabs-ink-bar {
	background-color: var(--default-btn-color);
	border-bottom: 2px solid var(--default-btn-color);
}

.ant-tabs-left {
	height: 100%;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
	padding: 16px 24px 16px 0px;
}
